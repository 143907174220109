import React from 'react';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
// import '../styles/Spinner.scss';

export default function Spinner() {
    return (
        <Dots />
    );
};
